<template>
  <div class="detailbg">
    <div style="padding-bottom: 80px">
      <div>
        <img style="width: 100%" :src="list.AImgUrl" alt="" />
      </div>
      <div>
        <div>
          <!-- <div class="detailsA">
            <span>¥ 0</span>
            <span>¥ 0</span>
            <span>已售: 788件</span>
          </div> -->
          <div style="margin: 10px 15px; font-size: 18px">{{ list.Title }}</div>
          <div class="detailsB">
            <span>{{ list.Theme }}</span>
            <!-- <span style="margin:0px 15px">|</span> 
            <van-icon name="clock" />
            <span style="margin-left:4px">30分钟</span> -->
          </div>
        </div>
        <div>
          <div
            style="
              margin: 10px 15px;
              font-size: 18px;
              border-bottom: 1px solid #e0e0e0;
              padding-bottom: 15px;
            "
          >
            志愿活动详情
          </div>
          <div
            style="margin: 10px 15px; font-size: 15px"
            v-html="list.Content"
          ></div>
        </div>
      </div>
    </div>
    <!-- 表单弹出框 -->
    <div>
      <van-popup
        v-model="detailFrom"
        round
        position="bottom"
        :style="{ height: '50%' }"
      >
        <div class="fromtitle">志愿者信息</div>
        <van-field
          v-model="datafrom.name"
          required
          label="姓名:"
          placeholder="请输入姓名"
        />
        <van-field
          v-model="datafrom.mobile"
          required
          type="tel"
          label="手机号:"
          placeholder="请输入手机号"
        />
        <van-field
          v-model="datafrom.IDcode"
          required
          label="身份证号:"
          placeholder="请输入身份证号"
        />
        <van-field
          v-model="datafrom.remark"
          rows="1"
          autosize
          label="备注:"
          type="textarea"
          placeholder="请输入备注"
        />
        <div class="detailon">
          <van-button round type="default" @click="detailFrom = false"
            >返 回</van-button
          >
          <van-button round type="info" @click="submitFrom(AId)"
            >提 交</van-button
          >
        </div>
      </van-popup>
    </div>
    <!-- 预定固定 -->
    <div class="detailsD">
      <div class="detailsDs">
        <button @click="submitFrom(AId)">立即参与</button>
        <button @click="SignActive(AId)" style="background:#47b347">打 卡</button>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { WeEnrolActivity,WxSignActive } from "@/api/yeguang";
import { WeGetActivityDetail } from "@/api/RealInfo";
import { setOpenId, getOpenId } from "@/utils/auth";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      detailFrom: false,
      datafrom: {
        name: "",
        mobile: "",
        IDcode: "",
        remark: "",
      },
      list: [],
      AId: undefined,
    };
  },
  created() {
    this.pagelist(this.$route.params.Id);
    this.AId = this.$route.params.Id;
  },
  methods: {
    // 获取详情
    pagelist(row) {
      WeGetActivityDetail({ aId: row }).then((res) => {
        this.list = res.data.data;
        if (this.list.AImg == "") {
          this.list.AImgUrl =
            "https://tkdlapi.zhsq.cloud//upload/images/2021/8/a64a40a3-045.jpg";
        }
      });
    },
    // 打卡
    SignActive(row){
      WxSignActive({Id: row,Group:2,OpenID:getOpenId()}).then((res)=>{
        if(res.data.code == 0){
          Dialog.alert({
            message: "打卡成功!",
          }).then(() => {
            // on close
          });
        }else{
          Dialog.alert({
            message: "打卡失败," + res.data.msg,
          }).then(() => {
            // on close
          });
        }
      })
    },
    // 报名
    submitFrom(row) {
      // if (this.datafrom.name == "") {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "请输入名称",
      //   }).then(() => {});
      //   return false;
      // }
      // if (this.datafrom.mobile == "") {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "请输入手机号",
      //   }).then(() => {});
      //   return false;
      // }
      // if (this.datafrom.IDcode == "") {
      //   Dialog.alert({
      //     title: "提示",
      //     message: "请输入身份证号",
      //   }).then(() => {});
      //   return false;
      // }
      WeEnrolActivity({ OpenID: getOpenId(), AId: row }).then((res) => {
        if (res.data.code == 0) {
          Dialog.alert({
            title: "提示",
            message: "申请成功!",
          }).then(() => {
            this.detailFrom = false;
          });
        } else {
          Dialog.alert({
            title: "提示",
            message: "申请失败!" + res.data.msg,
          }).then(() => {
            this.detailFrom = false;
          });
        }
      });
    },
  },
};
</script>
<style>
.detailsA {
  position: relative;
  margin: 10px 15px;
}
.detailsA span:nth-child(1) {
  color: #fb4e44;
  font-size: 24px;
}
.detailsA span:nth-child(2) {
  padding-left: 15px;
  font-size: 14px;
  color: #ccc;
}
.detailsA span:nth-child(3) {
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 14px;
}
.detailsB {
  display: flex;
  align-items: center;
  margin: 5px 15px;
  color: #666666;
  font-size: 14px;
}
.detailbg {
  background: #fff;
}
.detailsC {
  position: absolute;
  right: 25px;
  top: 7px;
}
.detailsD {
  position: fixed;
  bottom: 0px;
  height: 50px;
  width: 100%;
  background: #fafafa;
  border-top: 1px solid #ccc;
}
.detailsDs span {
  line-height: 50px;
  color: #fb4e44;
  margin: 0px 15px;
}
.detailsDs button {
  /* position: absolute;
  right: 0px; */
  float: right;
  width: 120px;
  line-height: 50px;
  color: #fff;
  background: #fb4e44;
  border: none;
}
.fromtitle {
  font-size: 18px;
  font-weight: 700;
  margin: 10px 15px;
}
.detailon {
  text-align: center;
  margin: 20px;
}
.detailon button {
  width: 100px;
  margin: 0px 30px;
}
</style>